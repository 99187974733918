var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Device Types")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.deviceTypes || [],"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
                name: 'main-admin-device-types-view',
                params: { deviceTypeId: item.id },
              }}},[_c('v-icon',[_vm._v("pageview")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
                name: 'main-admin-device-types-edit',
                params: { deviceTypeId: item.id },
            }}},[_c('v-icon',[_vm._v("edit")])],1),_c('DeleteButton',{attrs:{"itemID":item.id,"deleteFunction":_vm.deleteDeviceType}})]}}])})],1)],1),_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("New Device Type")])]),_c('v-card-text',[[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":"Name","data-vv-name":"name","data-vv-as":"name","error-messages":_vm.errors.collect('name'),"required":""},model:{value:(_vm.formData.createDeviceTypeName),callback:function ($$v) {_vm.$set(_vm.formData, "createDeviceTypeName", $$v)},expression:"formData.createDeviceTypeName"}}),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":"External-id","data-vv-name":"external-id","data-vv-as":"external-id","error-messages":_vm.errors.collect('external-id'),"required":""},model:{value:(_vm.formData.createDeviceTypeExternalId),callback:function ($$v) {_vm.$set(_vm.formData, "createDeviceTypeExternalId", $$v)},expression:"formData.createDeviceTypeExternalId"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2","sm":"2"}},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":_vm.createDeviceType}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("add")]),_c('span',[_vm._v("Add")])],1)],1)],1)],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }