


















































































import { Component, Vue } from 'vue-property-decorator';
import {CreateDeviceTypeDTO, OutputDeviceTypeDTO} from '@/api';
import {EventBus} from "@/main";
import {updateObjectList} from "@/utils";
import DeleteButton from "@/components/DeleteButton.vue";
import {deviceTypes} from "@/store/modules/deviceTypes";
import {AxiosError} from "axios";

@Component({
  components: {
    DeleteButton,
  }
})

export default class DeviceTypeList extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'External ID',
      sortable: true,
      value: 'external_id',
      align: 'left',
    },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
      value: 'actions',
    },
  ]
  public deviceTypes: OutputDeviceTypeDTO[]|null = null;

  public formData = {
    createDeviceTypeName: '',
    createDeviceTypeExternalId: '',
  }


  public async created() {
    EventBus.$on('deviceType', (data) => {
      this.deviceTypes = updateObjectList(this.deviceTypes, data);
    });
  }

  public async mounted() {
    await this.updateDeviceTypes();
    this.reset();
  }

  public async updateDeviceTypes() {
    const data = await this.$store.dispatch('deviceTypes/getAll');
    this.deviceTypes = data ?? [];
  }

  public reset() {
    this.formData = {
      createDeviceTypeName: '',
      createDeviceTypeExternalId: '',
    }
    this.$validator.reset();
  }

  public async createDeviceType() {
    if (await this.$validator.validateAll()) {
      const dto: CreateDeviceTypeDTO = {
        name: this.formData.createDeviceTypeName,
        external_id: this.formData.createDeviceTypeExternalId,
      };

      try {
        await this.$store.dispatch('deviceTypes/create', { data: dto });
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 400) {
          this.$store.commit('ui/addNotification', { content: 'Name or external ID is already in use.', color: 'error' })
          return;
        }
        throw error;
      }
    }

    window.location.reload();
  }

  public async deleteDeviceType(id) {
    await this.$store.dispatch('deviceTypes/remove', { deviceTypeId: id });
  }

  get loading() {
    return this.deviceTypes === null;
  }
}

